.SeminarWrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
    
}
.Seminar-RightSide{
    background-color:#ffffff;
}

.Seminar-LeftSide{
    background-color:#ffffff;
    
}

.Seminar-Button-Body{
    background-color:#E4A11B ;
    color:white;
    

}
.Seminar-Button-Body{
    margin-right: 20px;
    font-size:15px;
    border:1px solid #757575;
    border-radius: 5px;      
    width:120px;
    padding:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:#491030;
    color:#ffffff;
    box-shadow:0 0 2px #757575;
    cursor: pointer;
}

.SeminarImage-Mobile{
    display:none;
}


@media only screen and (max-width:900px){
    .SeminarImage{
        display:none;
    }
}    