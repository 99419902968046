.MainServiceWrapper{
    margin-top:5px;
    background-color:#fefef2;
    width:100vw;


}
.MainServiceContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainServiceHeader{
    margin-top:10px;
    font-size:20px;
    font-weight:bold;
    color:#f76c2f;
    padding:2px;
}

.Underline{
    height:2px;
    width:10rem;
    background-color:#f76c2f;
    margin-bottom: 5px;  
    
}

.MainServiceCard{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:85vw;
    margin-top: 10px;
    margin-bottom:20px;
}
.MainServiceCardBody{
    display:flex;
    flex-direction: column;
    justify-content: left;
    padding:10px;
    border:1px solid #757575;
    border-radius: 15px;  
    margin-right: 10px;
    background-color: #ffffff;
    box-shadow:0 0 2px #757575;
}

.CardHeader{
    font-size:18px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#616161;

}

.CardBodyTitle{
    margin-top:5px;
    font-size:16px;
}
.CardBodyContent{
    font-size:15px;
}

.CardButtonContent{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.CardButton{
    font-size:10px;
    padding:6px;
    border:1px solid #757575;
    border-radius: 18px;  
    cursor: pointer;
    background-color:#14A44D;
    color:#ffffff;
    width:100px;
    box-shadow:0 0 2px #757575;
}

@media only screen and (max-width:900px){
    .MainServiceCard{
        display:flex;
        flex-direction: column;
    }
    .MainServiceCardBody{
        padding:5px;
        margin-bottom:20px;
    }




}


