.SeminarForm{
    display:flex;
    flex-direction: row;
    justify-content: center;

}
.applicationForm{
    display:flex;
    flex-direction: column;
    justify-content: left;
    
}

.formHeader{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.Input-group{
    padding:8px;
}

.Personal{
    border:1px solid #757575;
    border-radius: 2px;   
    padding:5px;
    margin-top:10px;
}

.PersonalHeader{
    display:flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
    padding:5px;
    background-color:rgba(73,16,48,0.8);
    color:#ffffff;

}

.Personal-Text{
    flex-grow: 1;
}
.Personal-Button{
    flex-grow:1;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.RemoveButton{
    margin-right: 20px;
    font-size:10px;
    border:1px solid #757575;
    border-radius: 15px;      
    width:50px;
    padding:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:#dc3545;
    color:#ffffff;
    box-shadow:0 0 2px #757575;
    cursor: pointer;


}

.SeminarInfor{
    border:1px solid #757575;
    border-radius: 2px;   
    padding:5px;
    margin-top:10px;

}

.registry-btn{
    margin-right: 20px;
    font-size:13px;
    border:1px solid #757575;
    border-radius: 15px;      
    width:100;
    padding:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    background-color:#14A44D;
    color:#ffffff;
    box-shadow:0 0 2px #757575;
    cursor: pointer;
}