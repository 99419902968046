.LogoWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    
}
.LogoContent{
    display:flex;
    flex-direction: row;

}

.LogoImage{
    display:flex;
    justify-content: center;
    align-items: center;
}

.LogoText{
    display:flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    font-size: 18px;
    font-weight: bold;
    color:#ffffff;
}

@media only screen and (max-width:900px){
    .LogoText{
        display: flex;
        flex-direction: column;
        font-size:12px;
    }
    
}