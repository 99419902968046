.Course-Wrapper{
    display:flex;
    flex-direction: column;
    background-color:#f0f0f0;
}
.Course-Content{
    margin-top:10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    
}

.Course-Header{
    display: none;
    margin-top: 5px;
    font-size:15px;
    font-weight: bold;
    
} 

.Course-Body{
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    margin-left: 20vw;
    margin-right:20vw;
}

.Course-Body-Header{
    margin-top:20px;
    font-size:18px;
    font-weight:bold;
    color:#f76c2f;
    padding:2px;
}

.Course-Body-Context{
    margin-top: 20px;
    background-color: #ffffff;
    font-size:13px;
    padding:20px;
    margin-bottom: 20px;
}

#header{
    font-size:15px;
    font-weight: bold;
    color:#6d4c41;
}

.Course-Body-List{
    margin-top:20px;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.Course-Button{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}

.Button-Body{
    margin-right: 20px;
    font-size:13px;
    border:1px solid #757575;
    border-radius: 15px;      
    width:150px;
    padding:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:#14A44D;
    color:#ffffff;
    box-shadow:0 0 2px #757575;
    cursor: pointer;
}

/* Join Us*/
.JoinUs-Result{
    margin-top: 5px;
    border:1px solid #757575;
    border-radius: 18px; 
    font-size:13px;
    padding:2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    box-shadow:0 0 2px #757575;
}

.JoinUs-Result-Text{
    padding:5px;
    
}
.JoinUs-Result-Logo{
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.Course-Detail-Wrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
    
}

.Course-Detail{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color:#eeeeee;
    flex-grow: 1;
    margin-right: 10px;
    padding:5px;
}

.Course-Detail-Header{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.Course-Header-Logo{
    margin-right:5px;
}

.Course-Header-Text{
    font-size:14px;
    font-weight: bold;
}

.Course-Detail-Body{
    margin-top:5px;
    font-size:12px;
}

#Course-Time-Table-Mobile{
    display:none;
}



@media only screen and (max-width:900px){
    .Course-Wrapper{
        width:100vw;
    }

    .Course-Header-Image{
        display:none;
    }
    .Course-Header{
        display: flex;
        flex-direction: column;
        padding:2px;

        
    }    
   
    .Course-Body{
        margin-top: 10px;
        display:flex;
        flex-direction: column;
        width:100vw;
    }
    .Course-Body-Context{
        margin-left:2px;
        margin-right:2px;
    }
    #Course-Time-Table{
        display:none;
        
    }
    
    #Course-Time-Table-Mobile{
        display:flex;
        flex-direction: column;
        width: 30vw;
    }

    .Course{
        padding:3px;
        border:1px solid #757575;
        border-radius: 1px;  
        margin-bottom:5px;
    }




}    