.MediaBarWrapper{
    display:flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    color:#ffffff;
    height:50px;
    background-color:#410e2b;
    font-size:15px;
    font-weight: bold;
    width:100vw;
}

.MediaBarContent{
    margin-right:20px;
    padding:5px;
}
.WhatsApp{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.MediaLogo{
    margin-right:5px;
    margin-top: 5px;
}
.MediaText{
    font-size:13px;
}

a{
    text-decoration: none;
    color:#ffffff;
}


@media only screen and (max-width:900px){
    .MediaText{
        font-size:10px;
    }

}

