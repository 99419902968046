.mainScreen-Wrapper{
    display:flex;
    flex-direction:column;
    justify-content: center;
    width:100vw;
    
}
  .mainScreen-Content{
    background-image:url(../Image/mainscreen.png);
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    height:200px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    z-index:1;
    color:#f5f5f5;
  }

  .MainScreen-Button{
    display:flex;
    flex-direction: row;
    margin-bottom:10px;
  }

  .MainButton{
    margin-left:20px;
    font-size:13px;
    border:1px solid #ffffff;
    border-radius: 8px;      
    background-color: #3B71CA;
    width:150px;
    padding:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow:0 0 2px #757575;
    cursor: pointer;
    color:#ffffff;
  }
  
  .mainScreen-Content ul{
    list-style:none;
    font-size: 20px;
    margin-top: 0px;
    
  }
    
  .mainScreen-Content ul li{
    margin-top:5px;
  }


  @media only screen and (max-width:900px){

  /*--Main Screen*/
  .mainScreen-Content{
    padding-top:10px;
    height:200px;
    z-index:-1;
  }

  .mainScreen-Content ul{
    font-size: 15px;
  }

  .MainScreen-Button{
    padding-bottom:10px;
  }

  }