.Footer-wrapper{
    background-color: #491030;
    color:#ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    margin-top: auto;
    height:180px;
}

.Footer-content{
    margin-top:20px;
    font-size:12px;
    padding:10px;
    

}
