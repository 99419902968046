.NavBarWrapper{
    padding:10px;
    width: 100vw;
    height:80px;
    display:flex;
    flex-direction: row;
    background-color:#491030;

}    
.NavBar-Logo{
    flex:50%;
    display:flex;
    justify-content: left;
    margin-left:5px;
    
}

.NavBar-Content{
    flex:50%;
    display:flex;
    justify-content: left;
    align-items: center;
    
}

.NavBar-Links {
  max-height: 80px;
  display: flex;
  

}
/*

.NavBar-Links a{
    text-decoration: none;
    color:#ffffff;
    font-size: 15px;
    margin-left: 15px;
}

.ActiveLink a{
    color:red;
}
*/

.Link{
    color:#e0e0e0;
    text-decoration: none;
    font-size:13px;
    margin-left:15px;
}

.ActiveLink{
    color:#ffffff;
    text-decoration: none;
    font-size:13px;
    margin-left:15px;

}

.ToggleBtn{
    display: none;

}





@media only screen and (max-width:900px){
    .NavBar-Logo{
        flex:70;
        
    }
    .NavBar-Content{
        flex:30;
        justify-content: right;
        margin-right:10px;
    }

    .ToggleBtn{
        display: flex;
        margin-right: 10px;        

    }
    .NavBar-Links{
        display:none;
    }
    
    #hidden {
        position:absolute;
        right:0px;
        top:80px;
        width:60%;
        height:150px;
        max-height: 150px;
        background-color: #491030;
        opacity: 0.9;
        display: flex;
        flex-direction: column;
        align-items: left;
       
    }

    .Link{
        color:#e0e0e0;
        text-decoration: none;
        font-size:13px;
        padding:5px;
    }

    .ActiveLink{
        padding:5px;
        color:#ffffff;
    
    }

}
